import { useNavigate } from "react-router-dom";
import {
  Image,
  NavBar as RVNavBar,
  NavBarProps as RVNavBarProps,
} from "react-vant";
import styles from "./index.module.css";
import { useEffect, useMemo } from "react";
import { useReactive } from "ahooks";

export interface NavBarProps extends RVNavBarProps {
  theme?: "light" | "dark" | "none";
  onScroll?: (scrollTop: number) => void;
}

export default ({
  className,
  theme = "light",
  placeholder = true,
  onScroll,
  ...props
}: NavBarProps) => {
  const nav = useNavigate();
  const state = useReactive({
    scrollTop: 0,
  });
  useEffect(() => {
    const onPageScroll = () => {
      state.scrollTop = document.documentElement.scrollTop;
      onScroll && onScroll(state.scrollTop);
    };
    window.addEventListener("scroll", onPageScroll);
    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, [onScroll]);

  const scrollTop = useMemo(() => {
    return state.scrollTop / 100;
  }, [state.scrollTop]);
  return (
    <RVNavBar
      className={`${styles.container} ${
        theme !== "light"
          ? theme !== "none"
            ? styles.dark
            : styles.none
          : null
      } ${className}`}
      style={{
        ...(theme === "none"
          ? { backgroundColor: `rgba(255, 255, 255, ${scrollTop})` }
          : {}),
      }}
      leftText={
        <div
          className={`${styles.arrow} ${
            !placeholder ? styles.lightArrow : null
          }`}
        >
          {theme === "none" && scrollTop <= 0 ? (
            <>
              <Image
                className={styles.back}
                src={require("@/assets/icons/dark/back.svg").default}
              />
            </>
          ) : (
            <>
              <Image
                className={styles.back}
                src={require("@/assets/icons/light/back.svg").default}
              />
            </>
          )}
        </div>
      }
      onClickLeft={() => {
        nav(-1);
      }}
      leftArrow={false}
      border={false}
      fixed
      placeholder={placeholder}
      safeAreaInsetTop
      {...props}
    />
  );
};
